<template>
  <div class="tw-flex tw-w-full tw-flex-col setting tw-mt-10 tw-bg-white">
    <v-tabs
      fixed-tabs
      align-with-title
      v-model="tab"
      class="tw-pb-5 lg:tw-pb-0"
      slider-color="#F66B05"
      color="#F66B05"
    >
      <v-tab class="tw-justify-start switch-header tw-p-1">
        <v-icon class="tw-mr-2">mdi-account</v-icon>
        Profile
      </v-tab>
<!--      <v-tab class="tw-justify-start switch-header tw-p-1">-->
<!--        <icons-->
<!--          class="tw-mr-2"-->
<!--          name="key"-->
<!--          :color="tab === 1 ? companyData.companyColor : '#989CA0'"-->
<!--        />-->
<!--        Account Details-->
<!--      </v-tab>-->
          <v-tab class="tw-justify-start switch-header tw-p-1">
            <icons class="tw-mr-2" name="switch"  :color="tab === 1 ? '#F66B05' : '#989CA0'"/>
            Deactivate Account
          </v-tab>
      <v-tab class="switch-header tw-p-1 tw-justify-start">
        <icons
          class="tw-mr-2"
          name="change-password"
          :color="tab === 2 ? '#F66B05' : '#989CA0'"
        />
        Change Password
      </v-tab>
      <v-tab-item
        class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-p-5"
      >


            <div class="tw-flex tw-flex-row tw-w-full tw-justify-between">
              <div class="tw-w-5/12">
                <p class="desc-text">Profile Image</p>
                <button
                  class="button-logo-input"
                  type="button"
                  @click="onLogoFile()"
                  :class="image ? 'border-0' : ''"
                >
                  <v-progress-circular
                    :size="100"
                    :width="3"
                    :color="parentData.companyColor"
                    indeterminate
                    style="position: absolute"
                    v-if="logoImageLoading"
                  ></v-progress-circular>
                  <img
                    :src="parentData.profileImage"
                    alt=""
                    class="button-logo-img"
                    v-if="parentData.profileImage"
                  />
                  <img
                    :src="image"
                    alt=""
                    class="button-logo-img"
                    v-else-if="logoImage"
                  />
                  <div
                    class="parent-avatar"
                    v-else
                  >{{parentData.firstname.charAt(0)}}</div>
                </button>
                <input
                  type="file"
                  ref="logoImage"
                  accept="image/*"
                  style="display: none"
                  @change="onLogoImagePicked"
                />
              </div>
            </div>

            <v-row class="tw-flex-col lg:tw-flex-row tw-pt-10">
              <v-col sm="12" lg="6">
                <p class="desc-text">First Name</p>
                <v-text-field
                  v-model="parentData.firstname"
                  solo
                  hide-details
                  placeholder="Mark"
                ></v-text-field>
              </v-col>

              <v-col sm="12" lg="6">
                <p class="desc-text">Last Name</p>
                <v-text-field
                  v-model="parentData.lastname"
                  solo
                  hide-details
                  placeholder="David"
                ></v-text-field>
              </v-col>
            </v-row>

        <v-row class="tw-flex-col lg:tw-flex-row tw-pt-10">
          <v-col sm="12" lg="6">
            <p class="desc-text">Email</p>
            <v-text-field
              v-model="parentData.email"
              solo
              hide-details
              placeholder="example@gmail.com"
              type="email"
            ></v-text-field>
          </v-col>

          <v-col sm="12" lg="6">
            <p class="desc-text">Phone Number</p>
            <v-text-field
              v-model="parentData.phoneNumber"
              solo
              hide-details
              placeholder="+127223232"
              type="tel"
            ></v-text-field>
          </v-col>
        </v-row>

            <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">
              <v-btn
                :style="{ background: parentData.companyColor }"
                text
                class="save" @click="updateProfile" :loading="updateLoading"
                >Save</v-btn
              >
            </div>
          </v-tab-item>

          <v-tab-item   class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-pt-10" >
            <div class="deactivate-account-card tw-px-10 tw-pb-5">
            <v-row class="tw-flex-col lg:tw-flex-row tw-pt-10">
              <v-col sm="12" >
                <p class="desc-text">Why do you want to Deactivate your account?</p>
                <v-textarea rows="5" solo hide-details placeholder="Reasons" ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="tw-flex-col lg:tw-flex-row lg:tw-pt-10">
              <v-col sm="12" lg="6">
                <p class="desc-text">Email</p>
                <v-text-field solo hide-details placeholder="sorry@example.com"  type="email"></v-text-field>
              </v-col>
              <v-col sm="12" lg="6">
                <p class="desc-text">Password</p>
                <v-text-field solo hide-details placeholder="Password"  type="password"></v-text-field>
              </v-col>
            </v-row>
            <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">
              <v-btn text class="save" style="font-weight: bold" :style="{background:parentData.companyColor}">Deactivate Account</v-btn>
            </div>
            </div>
          </v-tab-item>

      <v-tab-item
        class="tw-flex tw-w-full tw-justify-center lg:tw-pt-20 tw-pt-10"
      >
        <div
          class="
            tw-flex tw-justify-center tw-w-full
            change-password-card
            tw-bg-white tw-py-10 tw-px-5
          "
        >
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(changePassword)">
              <div class="tw-w-full tw-flex-col">
                <v-col sm="12">
                  <ValidationProvider
                    name="Old Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">Old Password</p>
                    <v-text-field
                        :color="parentData.companyColor"
                      solo
                      hide-details
                      placeholder="Old password"
                      :append-icon="oldPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (oldPasswordValue = !oldPasswordValue)"
                      :type="oldPasswordValue ? 'text' : 'password'"
                      v-model="passwordData.oldPassword"

                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-col sm="12">
                  <ValidationProvider
                    name="New Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">New Password</p>
                    <v-text-field
                        :color="parentData.companyColor"
                      solo
                      hide-details
                      placeholder="New password"
                      v-model="passwordData.newPassword"
                      :append-icon="newPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (newPasswordValue = !newPasswordValue)"
                      :type="newPasswordValue ? 'text' : 'password'"
                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-col sm="12">
                  <ValidationProvider
                    name="Confirm Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">Confirm Password</p>
                    <v-text-field
                        :color="parentData.companyColor"
                      solo
                      hide-details
                      placeholder="Confirm password"
                      v-model="passwordData.confirmPassword"
                      :append-icon="confirmPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (confirmPasswordValue = !confirmPasswordValue)"
                      :type="confirmPasswordValue ? 'text' : 'password'"
                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">
                  <v-btn
                    text
                    class="save"
                    type="submit"
                    style="font-weight: bold"
                    :style="{ background: parentData.companyColor }"
                    :loading="ispasswordUpdateLoading"
                    >Change Password</v-btn
                  >
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </v-tab-item>
    </v-tabs>
    <modal />

  </div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import { updateGuardian, uploadToCloudinary } from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal";

import {
  getAllCitiesInAState,
  getAllCountries,
  getAllStatesByCountry,
  updatePassword,
} from "@/services/api/APIService";

export default {
  name: "AccountSetting",
  components: { Modal, Icons },
  data() {
    return {
      tab: null,
      logoImage: "",
      image: "",
      parentData: {
        address: {},
        accountDetail: {
          bankName: "",
          accountType: "",
          accountNumber: "",
          accountName: "",
          bvn: "",
        },
      },
      passwordData: {},
      ispasswordUpdateLoading: false,
      logoImageLoading: false,
      color: "#1E5770",
      profileTab: null,
      dialog: false,
      title: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      accountTypes: ["Savings", "Current", "Universal"],
      banks: [
        "GTBank",
        "UBA",
        "Zenith Bank",
        "Union Bank",
        "Fidelity Bank",
        "Sterling bank",
        "Stanbic IBTC",
        "Eco Bank",
        "Kuda bank",
      ],
      oldPasswordValue : false,
      newPasswordValue: false,
      confirmPasswordValue: false,
      updateLoading: false
    };
  },
  watch: {},
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    ChangeT(title) {
      this.title = title;
      this.parentData.companyColor = title;
      this.dialog = false;
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.parentData.profileImage = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          this.parentData.profileImage = response.data.url;

          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err.response);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.parentData.address.country,
        };
        if (this.parentData.address.country.name) {
          data.country = this.parentData.address.country.name;
        }
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {

        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.parentData.address.country,
            state: this.parentData.address.state,
          };
          if (this.parentData.address.country.name) {
            data.country = this.parentData.address.country.name;
          }
          if (this.parentData.address.state.name) {
            data.state = this.parentData.address.state.name;
          }
          const response = await getAllCitiesInAState(data);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }

    },
    async changePassword() {
      if (this.passwordData.newPassword === this.passwordData.confirmPassword) {
        this.ispasswordUpdateLoading = true;
        this.passwordData.email = this.parentData.companyEmail;
        await updatePassword(this.passwordData)
          .then((res) => {
            console.log(res.data);
            this.passwordData = {};
            this.ispasswordUpdateLoading = false;
            window.location.reload()
          })
          .catch((err) => {
            this.ispasswordUpdateLoading = false;
            this.$displaySnackbar({
              message: err.toString(),
              success: false,
            });
          });
      } else {
        this.$displaySnackbar({
          message: "New and Confirm Password do not match",
          success: false,
        });
      }
    },
    async updateProfile(){
      this.updateLoading = true;
      this.parentData.guardianId = this.parentData.id
      await updateGuardian(this.parentData).then(res =>{
        this.updateLoading = false;
        this.parentData = res.data
        sessionStorage.setItem("parentData",JSON.stringify(this.parentData))
        window.location.reload()
      }).catch((err) => {
        this.updateLoading = false;
        this.$displaySnackbar({
          message: err.toString(),
          success: false,
        });
      });
    }
  },
  mounted() {
    this.getCountries();
  },
  created() {
    let parentData = JSON.parse(sessionStorage.getItem("parentData"));
    if (parentData) {
      if (!parentData.accountDetail) {
        parentData.accountDetail = {};
      }
      this.parentData = parentData;
    }
  },
};
</script>

<style scoped lang="scss">
.switch-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}

::v-deep
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  @media screen and (max-width: 768px) {
    width: max-content !important;
    margin-right: 1rem !important;
  }
}

::v-deep .v-slide-group__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.desc-text {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 17, 39, 0.73);
}
.save {
  background: #F66B05;
  border: 1px solid rgba(229, 229, 229, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 135px !important;
  color: #ffffff;
  text-transform: none;
  height: 50px !important;
}

.card {
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 100%;
}

.account-details-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 638px;
  padding: 2rem;
}

.change-password-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 638px;
}

.deactivate-account-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 100%;
}

.card-inner {
  background: #fdfffc;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  min-height: 89px;
}

.card-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(29, 38, 45, 0.83);
}
.link {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #004aad;
  flex-wrap: wrap;
  flex-direction: row;
}

.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0 !important;
}

.setting {
  min-height: 75vh;
}

::v-deep .theme--light.v-tabs-items {
  background: none !important;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background: none !important;
}

.color-picker {
  height: 84px;
  width: 84px;
  padding: 20px 05px;
  // background: blue;
  border-radius: 100%;
  cursor: pointer;
}

.view-cert {
  height: 200px;
  width: auto;
  object-fit: contain;
}

.button-logo-input {
  display: flex;
  position: relative;

  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: none;
}
.button-logo-input .button-logo-img {
  width: fit-content;
  height: 100%;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}

.err {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: red;
}

.parent-avatar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F66B05;
  color: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
</style>